import de from 'vuetify/es5/locale/de'

/*
todo:
- HubIcons

next:
 -> buzzes/Explorere.vue
*/

export default {
    ...de,
    yes: "Ja",
    no: "Nein",
    close: "Schliessen",
    delete: "Löschen",
    add: "Hinzufügen",
    insert: "Einfügen",
    change: "Ändern",
    use: "Verwenden",
    show: "Anzeigen",
    all: "Alle",
    addPin: "Eintrag anpinnen",
    newEntry: "Neuer Eintrag",
    viewEntry: "Eintrag anschauen",
    curated: "Kuratiert",
    rulebased: "Regelbasiert",
    organisation: "Organisation",
    unsavedChanges: "Ungespeicherte Änderungen",
    copyToClipboardSucces: "In die Ablage kopiert",
    identifier: 'Identifier',
    label: 'Label',
    icon: 'Icon',
    image: 'Bild',
    youtube: 'YouTube',
    startTipping: 'Beginne zu Tippen',
    settings: 'Einstellungen',
    integrations: {
        areYouSureToLeave: "Möchten Sie die Seite wirklich verlassen? Alle ungespeicherten Änderungen gehen verloren."
    },
    layouts: {
        pinneditems: "Angepinnte Inhalte",
        pinremoved: "Pin wurde entfernt",
        pinadded: "Eintrag wurde gepinnt",
        entitySuccessQueue : {
            errorTitle: "Zeitüberschreitung",
            errorText: "Das Verarbeiten der Änderungen dauert aktuell länger als üblich. Die Änderungen wurden gespeichert und werden verarbeitet, sobald genügend Ressourcen zur Verfügung stehen.",
            saving: "Speichern..."
        },
        help: {
            title: "Support",
            inputPlaceholder: "Hilfe durchsuchen",
            noItems: "Bitte gib einen Suchbegriff ein, um das Helpcenter zu durchsuchen, oder öffne das Helpcenter direkt über den untenstehenden Link."
        },
        queue: {
            oneMoment: "Einen Moment bitte...",
            waitingText: "Wir sammeln alle nötigen Informationen zusammen und speichern die Daten"
        },
        reventItems: {
            title: "Arbeitsverlauf",
            empty: "Ziemlich leer hier.",
            emptyInfo: "Sobald du Inhalte, Content Pools oder Integrationen öffnest, erscheinen diese anschliessend im Arbeitsverlauf. So kannst du rascher zwischen verschiedenen Elementen hin und her springen."
        },
        search: {
            title: "Suchen",
            label: "Suchbegriff eingeben...",
            noDataText: "Suchbegriff mit mindestens 3 Buchstaben eingeben",
            minSearchLength: "Der Suchbegriff muss mindestens 3 Zeichen beinhalten",
            noResults: "Es wurden keine Einträge für den Begriff {0} gefunden"
        },
        terms: {
            title: "Allgemeine Geschäftsbedingungen &amp; Datenschutzerklärung",
            subtitle: "Bevor du durchstarten kannst, musst du unsere Allgemeine Geschäftsbedingungen &amp; Datenschutzerklärung akzeptieren:",
            acceptPrivacy: "Ich habe die Datenschutzerklärung gelesen",
            acceptTerms: "Ich akzeptiere die Allgemeinen Geschäftsbedingungen"
        },
        welcomeHelpOverlay: {
            title: "Willkommen bei Flyo",
            subtitle: "Nützliche Infos damit du sofort loslegen kannst.",
            doNotShowAgain: "Nicht mehr anzeigen"
        },
        notifications: {
            title: "Benachrichtigungen",
            crosschecks: "Cross Checks",
            quality: "Datenqualität",
            proposals: "Änderungsvorschläge",
            openComments: "Offene Aufgaben",
            personal: "Persönlich",
            approve: "Prüfen"
        }
    },
    footer: {
        terms: '<a href="/terms">AGBs</a> und <a href="/imprint">Impressum</a>'
    },
    attributes: {
        addressZip: 'PLZ (Postleitzahl)',
        addressCity: 'Ort',
        addressStreet: 'Strasse',
        addressStreetNr: 'Hausnummer',
        addressLat: 'Koordinaten (Breitengrad, Lat)',
        addressLng: 'Koordinaten (Längengrad, Long)',
        addressToggleAutoCords: 'Automatische Koordinaten',
        addressToggleCustomCords: 'Koordinaten selbst verwalten',
        typeConfigCustomElementSlugLabel: 'Element Name',
        typeConfigCustomElementSlugHint: 'Dieser Name wird in der Programmierung verwendet, um auf diese Elemente zuzugreifen.',
        typeConfigCustomElementHasContentLabel: 'Rekursive Inhaltseingabe',
        typeConfigCustomElementHasContentHint: 'Bei Aktivierung der Checkbox wird im benutzerdefinierten Element ein spezieller Bereich freigeschaltet, in den rekursiv weitere WYSIWYG-Inhalte eingefügt werden können. Diese Inhalte werden dann über die Variable {$content} abrufbar oder innerhalb des json mttels getContent(). Der Bereich unterstützt die Einfügung aller Standard-WYSIWYG-Elemente und behält die hierarchische Struktur der eingefügten Inhalte bei.',
        typeConfigCustomElementHtmlLabel: 'HTML Ausgabe',
        typeConfigCustomElementHtmlHint: 'Um das Element auch in HTML-kompatiblen Integrationen zu verwenden, kann es durch gültige HTML-Syntax definiert werden. Dabei sind alle Eigenschaften innerhalb der geschweiften Klammern und eine $-Zeichen {$beispiel} verfügbar. Angenommen, es gibt Eigenschaften namens `text` und `image`, könnte ein beispielhafter HTML-Code folgendermaßen aussehen: `<p><b>{$text}</b><img src="{$image}" border="0" alt="{$text}" /></p>`',
        typeConfigCustomElementLabelLabel: 'Anzeige-Name im Editor',
        typeConfigCustomElementAttributesHint: 'Eigenschaften sind Attribute, die einem HTML-Element zugeordnet werden und seine charakteristischen Merkmale definieren.',
        typeConfigCustomElementAttributesSubtitle: 'Eigenschaften',
        typeConfigCustomElementAttributesEmtpy: 'Es wurden noch keine Eigenschaften definiert.',
        typeConfigCustomElementAttributesFieldtype: 'Feldtyp *',
        typeConfigCustomElementAttributesAdd: 'Eigenschaften Hinzufügen',
        typeConfigCustomElementAdd: 'Benutzerdefiniertes Element Hinzufügen',
        typeMultipleinputAdd: 'Feld Hinzufügen',
        colorStoreNewColor: 'In Ablage Speichern',
        jsonInfoTitle: 'Dropdown Auswahl',
        jsonInfoText: 'Diese Liste wird dem Benutzer als Dropdown Auswahl angezeigt. Der Wert im ersten Feld entspricht dem Wert in der Datenbank. Der Wert rechts entspricht dem Wert, der dem Benutzer angezeigt wird (Label).',
        latLngLatLabel: 'Breitengrad/Lat',
        latLngLngLabel: 'Längengrad/Lng',
        linkTargetSameWindow: 'gleiches Fenster',
        linkTargetNewWindow: 'neues Fenster',
        linkTypeFile: 'Datei',
        linkTypeWebsite: 'Webseite',
        linkTypeEmail: 'E-Mail',
        linkTypePhone: 'Telefon',
        linkNitroPage: 'CMS Seite',
        openinghoursClosed: 'Geschlossen',
        openinghoursAddLunchBreak: 'Mittagszeit einfügen',
        openinghoursNoEntires: 'Verfügt über keine Öffnungszeiten',
        openinghoursAdd: 'Öffnungszeiten hinzufügen',
        openinghoursMo: 'Montag',
        openinghoursDi: 'Dienstag',
        openinghoursMi: 'Mittwoch',
        openinghoursDo: 'Donnerstag',
        openinghoursFr: 'Freitag',
        openinghoursSa: 'Samstag',
        openinghoursSo: 'Sonntag',
        orderingAddField: 'Feld Hinzufügen',
        orderingAscHint: 'entspricht aufsteigend von A-Z oder bei Zahlen 1,2,3',
        orderingDescHint: 'entspricht absteigend von Z-A oder bei Zahlen von 3,2,1',
        orderingDateInfo: 'Ein Feld mit Datum oder Zeitangaben wird in einen numerischen Wert (Unix Timestamp) umgerechnet. Um den am weitesten entfernten Eintrag zuerst zu sortieren, muss',
        orderingDateInfo2: 'verwendet werden. Um den neuesten Eintrag zuerst anzuzeigen, muss',
        orderingDateInfo3: 'verwendet werden.',
        poolTypesUsers: 'Benutzer',
        poolTypesFiles: 'Dateien',
        wysiwygSetLink: 'Link setzen',
        wysiwygUpdateLink: 'Link aktualisieren',
        wysiwygSetYoutube: 'YouTube Video',
    }
}
